/**
 * List all languages: GET ?target=''
 * Detect language: POST {params: {text: []}}
 * Translate language: POST {params: {text: [], target: ''}}
 * Translate language: POST {params: {text: [], source: '', target: ''}}
 */

import './App.css';
import React, { Component } from 'react';  
import axios from 'axios';
import AWS from 'aws-sdk';
import * as helper from './helper';

class App extends Component {
  constructor(props){  
    super(props);
    const user_translation = {
      accessKeyId: 'AKIAWN7Q3DS76VJZ3Z5I',
      secretAccessKey: '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'
    }
    AWS.config.update(user_translation);

    this.state= {
      langListing: {'all': []},
      langDetails: {},
      targetLangList: [ 'Loading Languages ...' ],
      targetLangSource: 'all',
      targetLang: 'English'
    };

    this.getAvailableLanguages = this.getAvailableLanguages.bind(this);
    this.translate = this.translate.bind(this);
    this.handleSourceLangChange = this.handleSourceLangChange.bind(this);
    this.handleTargetLangChange = this.handleTargetLangChange.bind(this);
  }

  componentDidMount() {
    this.getAvailableLanguages();
  }

  /**
   * get all available languages for translation
   * store these in 'availableLang' in 'state'
   */
  getAvailableLanguages() {
    if (this.state.targetLangList.length === 1) {
      helper.hideLoader(false);
      axios.get(helper.url)
        .then(resp => { 
          if (resp.data.statusCode === 200) {
            console.log('list of available languages', resp.data.body); 
            this.setState({ langListing: resp.data.body.listing, langDetails: resp.data.body.details, targetLangList: resp.data.body.listing.all })
          } else {
            alert('could not load languages, please try again');
            console.log(resp);
          }
          helper.hideLoader();
        })
        .catch(err => {
          helper.hideLoader();
          alert('Some error occured. Please check console for more info!')
          console.log(err);
        });
    }
  }

  /**
   * translate the source text to the target language
   */
  translate() {
    let params = helper.getValues(this.state.langDetails, this.state.targetLangSource); // {'sourceLang': '', 'targetLang: '', translationSource: ''}
    if (params.sourceLang === params.targetLang) {
      alert('Source language is same as target language');
      console.log('Source language same as target language. Were you testing us? Anyways! Let\'s not wait our API. :)');
      return;
    }
    if (!params.translationSource) {
      alert('Some error occurred. Please check the console for more info');
      console.log('This shouldn\'t have happened. We are very ashamed that it did. Somehow this combination of language is not working.');
      return;
    }

    params['sourceText'] = helper.getCleanSourceText(params.translationSource)
    if (params.sourceText === null) {
      alert ('please input text to translate');
      return;
    }

    helper.hideLoader(false);
    helper.resetOutputDiv();
    helper.translateBatch(params, 0);
  }

  /**
   * download the translated text into a .txt file
   */
  downloadOutput() {
    const ele = document.createElement("a");
    const content = document.getElementById('targetTextarea').value;
    if (content === ''){
      alert('The output box is empty!!');
      return;
    }
    const file = new Blob([content], {type: 'text/plain'});
    ele.href = URL.createObjectURL(file);
    ele.download = "myFile.txt";
    document.body.appendChild(ele); // for firefox
    ele.click();
  }

  /* on source language change */
  handleSourceLangChange(event) {
    this.setState({ targetLang: 'English' });

    const sourceLang = event.target.value;
    if (sourceLang === 'auto') {
      if (this.state.targetLangSource !== 'all') {
        this.setState({ targetLangSource: 'all', targetLangList: this.state.langListing.all });
      }
      return;
    }

    const in_google = sourceLang in this.state.langDetails.google;
    const in_bing = sourceLang in this.state.langDetails.bing;

    if (in_google && in_bing) {
      if (this.state.targetLangSource !== 'all') {
        this.setState({ targetLangSource: 'all', targetLangList: this.state.langListing.all });
      }
    } else if (in_google) {
      if (this.state.targetLangSource !== 'google') {
        this.setState({ targetLangSource: 'google', targetLangList: this.state.langListing.google });
      }
    } else if (in_bing) {
      if (this.state.targetLangSource !== 'bing') {
        this.setState({ targetLangSource: 'bing', targetLangList: this.state.langListing.bing });
      }
    } else {
      alert("Something went wrong. Check your console!");
      console.log("This should not have happened. We are ashamed for this to happen. We are not sure about how we got this language. Please contact the developer!!");
    }
  }

  /* on target language change */
  handleTargetLangChange(event) {
    if(event.target) {
      this.setState({ targetLang: event.target.value });
    } else {
      this.setState({ targetLang: 'English' });
    }
  }

  render() {
    return (
      <div className="App">
        <div className="loaderContainer" id="loaderContainer">
          <div className="loader"></div>
        </div>
        <div className="container" id="languageSelection">
          <select id="sourceLanguageSelector" onChange={this.handleSourceLangChange}>
            <option key="auto" value="auto">Auto Detect</option>
            {this.state.langListing.all.map((lang) => <option key={lang} value={lang}>{lang}</option>)}
          </select>
          <select id="targetLanguageSelector" value={this.state.targetLang} onChange={this.handleTargetLangChange}>
            {this.state.targetLangList.map((lang) => <option key={lang} value={lang}>{lang}</option>)}
          </select>
        </div>
        <div className="container">
          <textarea name="sourceTextArea" id="sourceTextArea" rows="25" className="half-width" placeholder="Input Text"></textarea>
          <textarea name="targetTextarea" id="targetTextarea" rows="25" className="half-width" placeholder="Output Text" disabled></textarea>
        </div>
        <div className="container">
          <button id="translate" onClick={this.translate}>Translate</button>
          <button id="downloadTranslatedText" onClick={this.downloadOutput}>Download Output</button>
        </div>
        <div className="container instructions">
          <div className="box">
            <p><b>Translates the text passed in the input textbox to the language selected in target dropdown.</b></p>
            <ul>
              <li><b>Req</b>: Input Text, Target Language</li>
              <li><b>Opt</b>: Source Language</li>
            </ul>
          </div>
          <hr/>
          <div className="box">
            <p><b>Downloads the content of output textbox in a txt file.</b></p>
            <ul>
              <li><b>Req</b>: Output text</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }  
}

export default App;