import axios from 'axios';
export const url = "https://xp9ag3ixei.execute-api.us-east-1.amazonaws.com/test/translate";

/**
 * show/hide loader
 * @param { boolean } hide if false, shows the loader. otherwise, hides it.
 */
export function hideLoader(hide = true) {
  const ele = document.getElementById('loaderContainer');
  if (hide) {
    ele.style.visibility = 'hidden';
  } else {
    ele.style.visibility = 'visible';
  }
}

/**
 * @param {number} maxPartitionSize: number of elements in each sublist
 * 128 seems to be the limit, but just to be on the safe side we will make it 120
 * @returns cleaned-up source text as a list of list
 */
export function getCleanSourceText(translationSource) {
  let maxPartitionSize = 120
  if (translationSource === 'bing') {
    maxPartitionSize = 90;
  }
  let sourceText = document.getElementById('sourceTextArea').value;
  sourceText = sourceText.split('\n').map(x => x.trim()).filter(x => x.length>0);
  if (sourceText.length === 0)
    return null;
  const partitionedSourceText = [];
  while(sourceText.length > maxPartitionSize) {
    partitionedSourceText.push(sourceText.splice(0, maxPartitionSize));
  }
  partitionedSourceText.push(sourceText);
  return partitionedSourceText;
}

function getSourceDetails(selectedSourceLang, selectedTargetLang, langDetails, targetLangSource) {
  let sourceLang, targetLang, translationSource;
  if (targetLangSource === 'google' || targetLangSource === 'bing') {
    sourceLang = langDetails[targetLangSource][selectedSourceLang].language;
    targetLang = langDetails[targetLangSource][selectedTargetLang].language;
    translationSource = targetLangSource
  } else if (targetLangSource === 'all') {
    if ((selectedSourceLang === 'auto' || selectedSourceLang in langDetails.google) && selectedTargetLang in langDetails.google) {
      sourceLang = (selectedSourceLang === 'auto') ? 'auto' : langDetails.google[selectedSourceLang].language;
      targetLang = langDetails.google[selectedTargetLang].language;
      translationSource = 'google'
    } else if ((selectedSourceLang === 'auto' ||selectedSourceLang in langDetails.bing) && selectedTargetLang in langDetails.bing) {
      sourceLang = (selectedSourceLang === 'auto') ? 'auto' : langDetails.bing[selectedSourceLang].language;
      targetLang = langDetails.bing[selectedTargetLang].language;
      translationSource = 'bing'
    }
  }
  return { sourceLang, targetLang, translationSource };
}

/**
 * get all input values in the webpage
 * @returns source language, target language and source text
 */
export function getValues(langDetails, targetLangSource) {
  let ele;
  ele = document.getElementById('sourceLanguageSelector');
  const selectedSourceLang = ele.options[ele.selectedIndex].value;
  ele = document.getElementById('targetLanguageSelector');
  const selectedTargetLang = ele.options[ele.selectedIndex].value;
  return getSourceDetails(selectedSourceLang, selectedTargetLang, langDetails, targetLangSource);
}

/**
 * set translated text in the div
 * @param { string[] } translatedData 
 */
export function setTranslatedText(translatedData) {
  const ele = document.getElementById('targetTextarea');
  translatedData.forEach((para) => {
    ele.innerHTML += para.translatedText + '\n';
  });
}

export function resetOutputDiv() {
  const ele = document.getElementById('targetTextarea');
  ele.innerHTML = '';
}

/**
 * set tranlated text by recursively translating strings in batches.
 * @param { { sourceLang: string, targetLang: string, sourceText: string[][] } } params
 * @param {number} index index of the sourceText to be translated in this recursion
 */
export function translateBatch(params, index) {
  if (index >= params.sourceText.length) {
    hideLoader();
    return;
  }
  const data = {
    targetLang: params.targetLang,
    translationSource: params.translationSource,
    sourceText: params.sourceText[index]
  };

  if (params.sourceLang !== '' && params.sourceLang !== 'auto') {
    data['sourceLang'] = params.sourceLang
  }

  axios.post(url, { params: data })
    .then(resp => {
      if (resp.data.statusCode === 200) {
        console.log(resp.data.body);
        setTranslatedText(resp.data.body);
        translateBatch(params, index+1);
      } else {
        alert('batch translation unsuccessful! Please try again');
        console.log(resp);
        this.hideLoader(true);
        this.resetOutputDiv();
      }
    })
    .catch(err => {
      alert('Some error occured. Please check console for more info!');
      hideLoader();
      console.log(err);
      this.resetOutputDiv();
    });
}